<template>
  <div class="card-menu" v-if="hasMenu">
    <div class="scrollbar-hide">
      <template v-if="inProcessDelivery">
        <div class="dp-wrap">
          <div class="dp-data">
            <div class="dp-data-title">{{ card.orderInfo.title }}</div>
            <div class="dp-data-text">{{ card.orderInfo.message }}</div>
            <div class="dp-data-button" v-if="canActivate">
              <button @click="changeStatus('ACTIVE', 'Card has been activated', 'To activate card enter the 6-digit verification code.')">Activate card</button>
            </div>
          </div>
          <div class="dp-line-wrap">
            <div class="dp-line done">
              <div class="dp-line-title">Ordered</div>
              <div class="dp-line-separator" />
              <div class="dp-line-subtitle">{{ orderedAt }}</div>
            </div>
            <div class="dp-line" :class="{done: dispatchedAtDone}">
              <div class="dp-line-title">Dispatched</div>
              <div class="dp-line-separator" />
              <div class="dp-line-subtitle">{{ dispatchedAt }}</div>
            </div>
            <div class="dp-line" :class="{done: verifiedAtDone}">
              <div class="dp-line-title">Delivered</div>
              <div class="dp-line-separator" />
              <div class="dp-line-subtitle">{{ verifiedAt }}</div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <ul class="card-menu-list">
          <li v-if="canFreeze" @click.stop="changeStatus('BLOCKED', 'Card has been frozen', 'To freeze card enter the 6-digit verification code.')">
            <icon-freeze />
            <span>Freeze card</span>
          </li>
          <li v-if="canDefrost" @click.stop="changeStatus( 'ACTIVE', 'Card has been unfrozen', 'To unfreeze card enter the 6-digit verification code.')">
            <icon-unfreeze />
            <span>Unfreeze card</span>
          </li>
          <li v-if="canShowDetails" @click.stop="showDetails">
            <icon-pin />
            <span>Card details</span>
          </li>
          <li v-if="canShowManageCard" @click.stop="showManageCard">
            <icon-limits />
            <span>Manage card</span>
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script>
import IconFreeze from '@/assets/images/order_cards/freeze.svg?inline'
import IconLimits from '@/assets/images/order_cards/limits.svg?inline'

import IconUnfreeze from '@/assets/images/order_cards/unfreeze.svg?inline'
import IconPin from '@/assets/images/order_cards/pin.svg?inline'

import ConfirmModal from "../../../modals/ConfirmModal";
import ManageCard from "../modals/ManageCard";
import CardDetails from "../modals/CardDetails";
import {mapGetters} from "vuex";

export default {
  name: 'CardMenu',
  components: {
    IconFreeze,
    IconLimits,
    IconUnfreeze,
    IconPin,
  },
  props: {
    /**
     * @class card
     *
     * @property {object}  card.permissions
     * @property {boolean} card.permissions.canClose
     * @property {boolean} card.permissions.canUnblock
     * @property {boolean} card.permissions.canBlock
     * @property {boolean} card.permissions.canGetCvv
     * @property {boolean} card.permissions.canGetNumber
     * @property {boolean} card.permissions.canGetPassword
     * @property {boolean} card.permissions.canGetPin
     * @property {boolean} card.permissions.canSetPassword
     * @property {boolean} card.permissions.canSetLimits
     * @property {boolean} card.permissions.canSetPin
     * @property {boolean} card.permissions.canActivate
     * @property {boolean} card.permissions.canGetLimits
     * @property {object}  card.notification
     * @property {object}  card.notification.type
     * @property {object}  card.notification.title
     * @property {object}  card.notification.description
     * @property {object}  card.orderInfo
     * @property {object}  card.orderInfo.delivery
     * @property {object}  card.orderInfo.ordered_at
     * @property {object}  card.orderInfo.dispatched_at
     */
    card: Object
  },
  computed: {
    ...mapGetters(['permissions']),
    canFreeze () {
      return this.card.permissions.canBlock
          && this.permissions.canCardBlock
    },
    canDefrost () {
      return this.card.permissions.canUnblock
          && this.permissions.canCardUnblock
    },
    canClose () {
      return this.card.permissions.canClose
          && this.permissions.canCardClose
    },
    canActivate () {
      return this.card.status === 'DISPATCHED'
          && this.card.permissions.canActivate
          && this.permissions.canCardActivate
    },
    canShowDetails () {
      return (this.card.permissions.canGetCvv && this.permissions.canCardCvvView)
          || (this.card.permissions.canGetNumber && this.permissions.canCardNumberView)
          || (this.card.permissions.canGetPassword && this.permissions.canCardPasswordView)
          || (this.card.permissions.canGetPin && this.permissions.canCardPinView)
          || (this.card.permissions.canSetPassword && this.permissions.canCardPasswordEdit)
          || (this.card.permissions.canSetPin && this.permissions.canCardPinEdit)
    },
    canChangeLimits () {
      return this.card.permissions.canGetLimits
    },
    canShowManageCard () {
      return this.canChangeLimits
          || (this.canClose && this.permissions.canCardClose)
    },
    inProcessDelivery () {
      return ['ORDERED', 'DISPATCHED'].includes(this.card.status)
          && this.card.orderInfo
    },
    hasMenu () {
      return this.canFreeze
          || this.canShowDetails
          || this.canClose
          || this.canDefrost
          || this.canShowManageCard
          || this.inProcessDelivery
    },

    orderedAt () {
      let time = this.card.orderInfo.ordered_at
      return time ? this.$moment(time * 1000).format('D MMM.') : null
    },
    dispatchedAt () {
      let time = this.card.orderInfo.dispatched_at
      return time ? this.$moment(time * 1000).format('D MMM.') : null
    },
    verifiedAt () {
      const deliveryInfo = this.card.orderInfo.delivery

      if (deliveryInfo) {
        let min = deliveryInfo[0],
            max = deliveryInfo[1]

        if (min === max) {
          return 'Appr. ' + this.$moment(min * 1000).format('D MMM.')
        } else {
          return 'Appr. ' + this.$moment((max || max) * 1000).format('D MMM.')
        }
      } else {
        return ''
      }
    },
    dispatchedAtDone () {
      return this.card.status === 'DISPATCHED'
    },
    verifiedAtDone () {
      const deliveryInfo = this.card.orderInfo.delivery

      if (Array.isArray(deliveryInfo) && deliveryInfo.length > 0) {
        let currTime = this.$moment().unix()
        let lastDate = deliveryInfo[deliveryInfo.length - 1]
        return currTime > lastDate
      }

      return false
    },
  },
  methods: {
    showManageCard () {
      this.$modal.show(ManageCard, {
        card_id: this.card.id
      })
    },

    showDetails () {
      this.$modal.show(CardDetails, {
        card_id: this.card.id,
        title: this.card.masked_number
      })
    },

    //actions
    changeStatus (status, message, subtitle, buttonClass) {
      this.$modal.show(ConfirmModal, {
        subtitle: subtitle,
        codeParam: 'errors.code',
        buttonClass: buttonClass,
        isSuccess: data => data.success,
        submit: ({ code }) => {
          return this.$sdk.card.update(this.card.id, {
            code: code,
            status: status
          }, 0).then(response => {
            if (response.data.success) {
              this.$notice.success(message)
            }
            return response
          })
        }
      })
    },
  }
}
</script>

<style lang="less">
.card-menu-list {
  font-family: "Graphik", serif;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  width: 100%;
  padding: 20px 25px;

  li {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    cursor: pointer;
    transition: color .3s ease;
    flex: 1;

    justify-self: center;
    justify-items: center;
    justify-content: center;

    max-height: 24px;
    position: relative;

    &:after {
      content: ' ';
      display: block;
      opacity: 0;
      height: 24px;
      width: 24px;

      background-image: url("/assets/images/order_cards/go.svg");
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -12px;
      transition: opacity .2s ease;
    }

    &:hover {
      color: #FDE46A;

      &:after {
        opacity: 1;
      }
    }

    svg {
      margin-right: 4px;
    }

    * {
      vertical-align: middle;
    }
  }
}

.dp {
  &-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    display: grid;
    border-radius: 14px;
  }

  &-data {
    padding: 15px;
    background-color: #FDE46A;
    border-radius: 14px 14px 0 0;
    min-height: 113px;
    color: #19191C;

    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 1px;

    &-title {
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 18.42px;
    }
    &-text {
      font-size: 11px;
      letter-spacing: 0;
      line-height: 18px;

      opacity: 0.8;
    }
    &-button {
      padding-top: 9px;

      button {
        padding: 11px 15px 9px 15px;
        border-radius: 15px;
        background-color: #19191C;
        text-transform: uppercase;
        color: #FFF;
        font-size: 10px;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }

  &-line {
    display: grid;
    grid-row-gap: 6px;

    &-wrap {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
      width: 100%;
      flex-basis: fit-content;
      justify-content: center;
      padding: 15px;
    }

    &-subtitle,
    &-title {
      opacity: 0.7;
      color: #FFFFFF;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 12px;
      height: 12px;
      //margin: 6px 0;
    }

    &-separator {
      opacity: 0.2;
      border-radius: 3px;
      background-color: #FFFFFF;
      height: 1px;
    }
  }
}

.dp-line {
  &.done {
    .dp-line-title { color: #FDE46A; opacity: 1; font-weight: 500; }
    .dp-line-subtitle { color: #fff; opacity: 1 }
    .dp-line-separator { background-color: #FDE46A; opacity: 1; height: 2px; }
  }
}
</style>
