<template>
  <div class="modal small">
    <div class="modal-content">

      <div @click="$emit('close')" class="menu-close" data-dismiss="modal" aria-label="Close">
        <span class="menu-close-icon" />
      </div>

      <div class="modal-content-title"> <icon-visa /> {{ title }}</div>

      <tabs v-model="tab" :items="items" class="tabs-100" :disabled="!show"/>

      <div class="modal-content-inner">

        <div class="content-block" v-if="tab">
          <template v-if="tab.id === 'details'">

            <form-group class="card_holder" label="Cardholder name">
              <template v-if="show">
                <span class="copy-item nowrap">
                  {{ card.cardholder_name || 'Not set' }}
                  <icon-copy @click="copy(card.cardholder_name)" />
                </span>
              </template>
              <template v-else>
                <span class="blur">11111 111111</span>
              </template>
            </form-group>

            <form-group class="card_number" label="Card number">
              <template v-if="show" >
                <span class="copy-item">
                  {{ formatNumber(card.number) || 'Not set' }}
                  <icon-copy @click="copy(card.number)" />
                </span>
              </template>
              <template v-else>
                <span class="blur">1111 1111 1111 1111</span>
              </template>
            </form-group>

            <form-group class="card_until" label="Expire date" style="white-space: nowrap">
              <template v-if="show">
                {{ card.validUntilDate || 'Not set' }}
              </template>
              <template v-else>
                <span class="blur">11/11</span>
              </template>
            </form-group>

            <form-group class="card_cvc" label="CVV/CVC">
              <template v-if="show">{{ card.cvv || 'Not set' }}</template>
              <template v-else>
                <span class="blur">355</span>
              </template>
            </form-group>

          </template>

          <template v-if="tab.id === 'password'">
            <form-group label="Static password">
              <template v-if="!show">
                <span class="blur">***********</span>
              </template>
              <template v-else>
                <span class="copy-item">
                  {{ card.password || 'Not set' }}
                  <icon-copy @click="copy(card.password)"/>
                </span>
              </template>
            </form-group>
          </template>

          <template v-if="tab.id === 'pin'">
            <form-group label="PIN code">
              <template v-if="!show">
                <span class="blur">****</span>
              </template>
              <template v-else>
                <span class="copy-item">
                  {{ card.pin || 'Not set' }}
                  <icon-copy @click="copy(card.pin)"/>
                </span>
              </template>
            </form-group>
          </template>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import Tabs from "../../../../libs/Tabs";
import FormGroup from "../../../../libs/forms/FormGroup";
import IconCopy from '@/assets/images/icons/ic_copy.svg?inline'
import IconVisa from '@/assets/images/card-details/visa.svg?inline'

export default {
  name: 'CardDetails',
  components: {
    Tabs,
    FormGroup,
    IconCopy,
    IconVisa
  },
  props: {
    card_id: {
      type: [Number, String]
    },
    title: String
  },
  data () {
    return {
      tab: null,
      /**
       * @property {string} masked_number
       * @property {string} cardholder_name
       * @property {string} validUntilDate
       * @property {string} cvv
       */
      card: null
    }
  },
  async mounted() {
    this.$sdk.card.info(this.card_id, ['pin', 'password', 'cvv', 'number']).then(({ data }) => {
      this.card = data
    })
  },
  computed: {
    show () {
      return !!this.card
    },
    items () {
      return [
        {
          id: 'details',
          label: 'Details',
          show: true,
        },
        {
          id: 'pin',
          label: 'PIN',
          show: this.canGetPin,
        },
        {
          id: 'password',
          label: 'Static password',
          show: this.canGetPassword,
        }
      ].filter(i => i.show)
    },

    canGetPassword () {
      return this.card
          ? this.card.permissions.canGetPassword
          : false
    },

    canGetPin () {
      return this.card
          ? this.card.permissions.canGetPin
          : false
    }
  },
  methods: {
    copy (text) {
      this.$copyText(text).then(() => {
        this.$notice.success('The text has been copied')
      })
    },
    formatNumber (number) {
      return number && number.replace(/(.{4})/g, "$1 ");
    }
  }
}
</script>

<style lang="less" scoped>
.tabs {
  grid-gap: 40px;

  &-100 {
    width: 100%;
  }
}

.modal-content { max-width: 542px; }
.content-block {
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 30px;
  justify-content: flex-start;
  width: 100%;

  @media all and (max-width: 600px) {
    grid-template-columns: minmax(180px, 345px);
    grid-gap: 30px;
  }

  font-size: 15px;
  font-weight: 500;
}

.roboto {
  font-family: "Roboto Mono", serif;
  line-height: 24px;
}

.copy-item {
  display: inline-block;
  align-items: center;
  align-content: center;
  gap: 10px;
  line-height: 1em;
  padding-right: 30px;
  position: relative;

  svg {
    height: 18px;
    width: 16px;

    fill: #FFF;
    cursor: pointer;
    position: absolute;

    right: 0;
    top: -2px;
  }
}

.blur {
  filter: blur(3px);
}

.modal-content-title {
  display: flex;
  gap: 10px;
  align-items: center;
}
</style>
