<template>
  <div class="modal small">
    <div class="modal-content" v-if="card">

      <div @click="$emit('close')" class="menu-close" data-dismiss="modal" aria-label="Close">
        <span class="menu-close-icon" />
      </div>

      <div class="modal-content-section">
        <h3 class="modal-content-title">
          Manage card
        </h3>

        <tabs v-model="active" :items="items" class="limits-tabs" />
        <template v-if="active">
          <component :is="active.component" :card="card" @update-card="loadCard" />
        </template>
      </div>

    </div>
  </div>
</template>

<script>
import Tabs from "../../../../libs/Tabs";

import ChangeLimits from "./manage/ChangeLimits";
import CardClose from "./manage/CloseCard";
import ChangePin from "./manage/ChangePin"
import ChangePassword from "./manage/ChangePassword"

export default {
  name: 'ManageCard',
  components: {
    Tabs
  },
  props: {
    card_id: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      active: null,
      card: null
    }
  },
  async created() {
    await this.loadCard()
  },
  computed: {
    items () {
      return [
        {
          id: 'card_limits',
          label: 'Change card limits',
          component: ChangeLimits,
          isEnabled: () => {
            if (this.card) {
              return this.card.permissions.canGetLimits
                  || this.card.permissions.canSetLimits
            }

            return false
          }
        },
        {
          id: 'pin',
          label: 'PIN',
          component: ChangePin,
          isEnabled: () => this.card.permissions.canSetPin,
        },
        {
          id: 'password',
          label: 'Static password',
          component: ChangePassword,
          isEnabled: () => this.card.permissions.canSetPassword,
        },
        {
          id: 'card_close',
          label: 'Close card',
          component: CardClose,
          isEnabled: () => {
            if (this.card) {
              return this.card.permissions.canClose
            }

            return false
          }
        }
      ].filter(i => i.isEnabled())
    }
  },
  methods: {
    loadCard () {
      return this.$sdk.card
          .info(this.card_id, ['limits', 'operations'])
          .then(response => {
            this.card = response.data
          })
    }
  }
}
</script>
