<template>
  <div class="close-card-block" v-if="card">
    <h3 class="close-card-block-title">Close card</h3>

    <div class="close-card-block-text modal-content-inner">
      The card will be permanently terminated. <br/> You will NOT be able to use it again. Do you want to proceed?
    </div>

    <div class="modal-content-bottom-panel">
      <div class="pull-left">
        <button type="button"
                class="default-button button-red"
                :disabled="!card.permissions.canClose"
                @click="cardClose"
        >Close card</button>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmModal from "../../../../modals/ConfirmModal";

export default {
  name: 'CloseCard',
  props: {
    card: {
      type: Object,
      required: true
    }
  },
  methods: {
    cardClose () {
      this.$modal.show(ConfirmModal, {
        codeParam: 'errors.code',
        buttonClass: ['default-button', 'button-block', 'button-red'],
        submit: ({ code }) => {
          return this.$sdk.card.update(this.card.id, {
            code: code,
            status: 'CLOSED'
          }, 0).then(response => {
            let data = response.data
            if (data && data.success) {
              this.$modal.hideAll()
              this.$notice.success('Card has been closed')
            }

            return response
          })
        },
        isSuccess: data => data.success
      })
    }
  }
}
</script>

<style lang="less" scoped>
.button-red {
  background-color: #EA4C17;
  border-color: #EA4C17;
  color: #FFF;
}

.close-card-block {
  display: flex;
  gap: 10px;
  flex-direction: column;

  &-title {
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
  }

  &-text {
    opacity: 0.7;
    color: #FFFFFF;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.45em;
  }
}

.modal-content-title {
  margin-bottom: 0;
}
</style>