<template>
  <div>
    <div class="content-block">
      <card :type="card.type" />
      <div>

        <form-group label="Change static password" :error="errors.password">
          <password-field v-model="form.password" @input="validate"/>
          <div class="pin-desc">Password must contain at least 8 characters, include at least one capital letter, one number and a special symbol. <br>Example: <span class="yellow-colored">qWerty99#!</span></div>
        </form-group>

      </div>
    </div>

    <hr>

    <div class="form-buttons text-right">
      <button class="default-button" role="button" :disabled="!isButtonEnabled" @click="submit">Change static password</button>
    </div>
  </div>
</template>

<script>
import Card from "../../parts/Card";
import FormGroup from "../../../../../libs/forms/FormGroup";
import ConfirmModal from "../../../../modals/ConfirmModal";
import debounce from 'lodash/debounce'
import PasswordField from "../../../../../libs/PasswordField";

export default {
  name: 'ChangePassword',
  props: {
    card: {
      type: Object,
      required: true
    }
  },
  components: {
    Card,
    FormGroup,
    PasswordField
  },
  data () {
    return {
      loading: false,
      errors: {},
      form: {
        password: ''
      }
    }
  },
  computed: {
    isButtonEnabled () {
      return this.form.password
          && Object.keys(this.errors).length === 0
          && !this.loading
    }
  },
  methods: {
    validate () {
      this.loading = true
      this.virtualValidate()
    },

    virtualValidate: debounce(function () {
      this.loading = true

      return this.$sdk.card.update(this.card.id, {
        password: this.form.password,
      }, 1).then(({ data }) => {
        if (!data.success) {
          this.errors = data.errors
        } else {
          this.errors = {}
        }
      }).finally(() => {
        this.loading = false
      })
    }, 500),

    submit () {
      this.loading = true

      this.$modal.show(ConfirmModal, {
        codeParam: 'errors.code',
        isSuccess: data => data.success,
        beforeClose: () => this.loading = false,
        submit: ({ code }) => {
          return this.$sdk.card.update(this.card.id, {
            password: this.form.password,
            code: code
          }, 0).then(response => {
            if (response.data.success) {
              this.errors = {}
              this.form.password = ''
              this.$emit('update-card')
            }

            return response
          })
        },
      })
    }
  }
}
</script>


<style lang="less" scoped>
.content-block {
  display: grid;
  grid-template-columns: 345px 1fr;
  grid-gap: 30px;
  //justify-content: center;
  width: 100%;

  @media all and (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }

  & > .card-svg {
    max-height: 215px;
    max-width: 345px;
    width: 100%;
  }
}

hr {
  margin: 40px 0 30px;
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.pin-desc {
  padding-top: 10px;
  color: rgba(255, 255, 255, .6);
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;

  & + .pin-desc {
    margin-top: 15px
  }
}
</style>
