<template>
  <div id="cards" class="main-content">
    <div class="card-filter-wrap scrollbar-hide">
      <ul v-if="cardsCounter" class="card-filter">
        <li
          v-for="item in filteredStatuses"
          :key="item.id"
          :class="{ active: hasStatus(item.id) }"
          @click="toggleStatus(item.id)"
        >
          {{ item.label }} <span class="marker">{{ item.count }}</span>
        </li>
      </ul>
    </div>
    <div class="shadow-line">
      <div class="cards-list-container scrollbar-hide">
        <div class="cards-list">
          <card-item v-for="(item, index) in cards" :item="item" :key="index" />
        </div>

        <infinite-loading :identifier="identifier" @infinite="loadMore" />
      </div>
    </div>
  </div>
</template>

<script>
import CardItem from './parts/CardItem';
import InfiniteLoading from "vue-infinite-loading";

import { isEqualInt } from "../../../libs/functions";

const GROUP_ORDERED = 'ORDERED'
const GROUP_ACTIVE  = 'ACTIVE'
const GROUP_CLOSED  = 'CLOSED'
const GROUP_BLOCKED = 'BLOCKED'
const GROUP_DISPATCHED = 'DISPATCHED'

export default {
  name: 'Cards',
  components: {
    CardItem,
    InfiniteLoading
  },
  props: ['id'],
  data () {
    return {
      cards: [],
      page: 1,
      pageLimit: 20,
      identifier: +new Date(),
      loading: false,
      cardsCounter: null,

      activeStatus: GROUP_ACTIVE,
      defaultStatuses: [
        GROUP_ACTIVE,
        GROUP_BLOCKED
      ],
      statuses: [
        {
          id: GROUP_ACTIVE,
          label: 'Active',
          count: 0,
          filter: [GROUP_ACTIVE]
        },
        {
          id: GROUP_BLOCKED,
          label: 'Blocked',
          count: 0,
          filter: [GROUP_BLOCKED]
        },
        {
          id: GROUP_ORDERED,
          label: 'Ordered',
          count: 0,
          filter: [GROUP_ORDERED, GROUP_DISPATCHED]
        },
        {
          id: GROUP_CLOSED,
          label: 'Closed',
          count: 0,
          filter: [GROUP_CLOSED]
        }
      ]
    }
  },
  async created() {
    await this.updateCounters()
  },
  sockets: {
    async 'createCard' ({ id, wallet_id }) {
      if (!isEqualInt(this.id, wallet_id)) return

      let index = this.cards.findIndex(i => isEqualInt(i.id, id))
      const { data } = await this.$sdk.card.info(id)

      if (index === -1 && this.isCardBelongsToActiveTab(data)) {
        this.cards.unshift(data)

        if (this.cards.length > this.pageLimit) {
          this.cards.splice(this.cards.length - 1, 1)
        }
      }

      await this.updateCounters()
    },
    async 'updateCard' ({ id, wallet_id }) {
      if (!isEqualInt(this.id, wallet_id)) return

      let index = this.cards.findIndex(i => isEqualInt(i.id, id))
      let { data } = await this.$sdk.card.info(id)

      if (index > -1 && this.isCardBelongsToActiveTab(data)) {
        this.isCardBelongsToActiveTab(data)
            ? this.cards[index] = data
            : this.cards.splice(index, 1)
        this.cards[index] = data
      }

      await this.updateCounters()
    },
    'deleteCard' ({ wallet_id }) {
      if (!isEqualInt(this.id, wallet_id)) return

      this.reset()
    },
  },
  computed: {
    filteredStatuses () {
      return this.statuses.filter(i => {
        return i.count > 0 || this.defaultStatuses.includes(i.id)
      })
    },
    activeTab () {
      return this.statuses.find(i => {
        return this.activeStatus === i.id
      })
    }
  },
  watch: {
    filteredStatuses () {
      if (this.activeTab && this.activeTab.count <= 0) {
        let item = this.statuses.find(status => status.count > 0)
        if (item && (this.activeStatus !== item.id)) {
          this.activeStatus = item.id
          this.reset()
        }
      }
    }
  },
  methods: {
    /**
     * Check if can add card to current tab card list
     */
    isCardBelongsToActiveTab(card) {
      return this.activeTab.filter.includes(card.status)
    },

    /**
     * Reset items list
     */
    reset () {
      this.page = 1
      this.cards = []
      this.identifier++
      this.updateCounters()
    },

    /**
     * Load more items
     *
     * @param $state
     */
    loadMore ($state) {
      this.loading = true

      this.$sdk.card.list({
        page: this.page,
        wallet_id: this.id,
        status: this.activeTab.filter
      }).then(({ data, headers }) => {
        this.pageLimit = parseInt(headers['x-pagination-per-page'])

        if (Array.isArray(data) && data.length > 0) {
          this.cards.push(...data)
          $state.loaded()
        }

        this.page++

        let currentPage = parseInt(headers['x-pagination-current-page'])
        let maximumPage = parseInt(headers['x-pagination-page-count'])

        if (currentPage >= maximumPage) {
          $state.complete()
        }
      }).finally(() => {
        this.loading = false
      })
    },

    /**
     * Get wallet cards count
     */
    async getCardsCount () {
      const { data } = await this.$sdk.wallet.show(this.id, ['cardCount'])
      this.cardsCounter = data.cardCount
    } ,

    /**
     * Update all counters
     */
    async updateCounters () {
      await this.getCardsCount()
      this.setCounters()
    },

    /**
     * Set cards counters
     * @param headers
     */
    setCounters () {
      this.statuses.forEach(i => {
        let sum = 0

        for (let a of i.filter) {
          sum += parseInt(this.cardsCounter[a])
        }
        i.count = sum
      })
    },

    hasStatus (status) {
      return this.activeStatus === status
    },

    toggleStatus (val) {
      if (this.loading) return

      this.activeStatus = val

      this.reset()
    },
  }
}
</script>

<style lang="less">
.cards-list {
  display: grid;
  grid-gap: 30px;

  margin-top: 30px;

  grid-template-columns: repeat(3, minmax(260px, 360px));

  &-container {
    height: calc(100vh - 263px);
    padding: 0 30px 20px;
  }
}

@media all and (max-width: 1280px) {
  .cards-list {
    grid-template-columns: repeat(2, minmax(260px, 360px));
  }
}

@media all and (max-width: 930px) {
  .cards-list {
    grid-template-columns: repeat(1, minmax(260px, 360px));
  }
}

.card-filter {
  display: flex;
  gap: 10px;

  li {
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 15px;
    padding: 5px 8px 5px 10px;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;

    display: flex;
    align-content: center;
    align-items: center;

    transition: all 0.2s ease;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }

    &.active {
      background-color: #ffffff;
      border-color: transparent;
      color: #19191c;
      opacity: 1;

      .marker {
        background-color: rgba(25, 25, 28, 0.1);
        color: #19191c;
      }
    }

    .marker {
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 0.1);
      padding: 3px 5px;
      min-width: 20px;
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
    }
  }

  &-wrap {
    display: flex;
    align-items: center;
    padding: 15px 30px 0;
    gap: 10px;
  }
}
</style>
