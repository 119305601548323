<template>
  <div class="card" :class="[cardStatusClass, cardImageClass]">
    <div class="card-content">
      <card :type="item.type" />
      <template v-if="isOrderedCard">
        <div class="card-content-marker">Card has been {{ item.status.toLowerCase() }}</div>
      </template>
      <template v-else-if="item.masked_number">
        <div class="card-content-marker">{{ item.masked_number }}</div>
      </template>
    </div>
    <card-menu :card="item" />
    <card-menu-notification :notification="item.notification" v-if="item.notification" />
  </div>
</template>

<script>
import Card from "./Card";
import CardMenu from "./CardMenu";
import CardMenuNotification from "./CardMenuNotification";
import {mapState} from "vuex";

export default {
  name: 'CardItem',
  components: {
    CardMenuNotification,
    CardMenu,
    Card
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      company: state => state.company.data,
    }),
    cardStatusClass () {
      return {
        frozen: this.item.status === 'BLOCKED',
        closed: this.item.status === 'CLOSED',
      }
    },
    cardImageClass () {
      const cardType = this.item.type.toLowerCase()
      const companyType = this.company.type.toLowerCase()

      return `${cardType}-${companyType}`
    },
    isOrderedCard () {
      return ['ORDERED', 'DISPATCHED'].includes(this.item.status)
    }
  }
}
</script>


<style lang="less">
@set: {
  disposable-business: url("~@/assets/images/order_cards/frozen/disposable-business.svg") ;
  disposable-individual: url("~@/assets/images/order_cards/frozen/disposable-individual.svg") ;
  chipandpin-business: url("~@/assets/images/order_cards/frozen/chipandpin-business.svg") ;
  chipandpin-individual: url("~@/assets/images/order_cards/frozen/chipandpin-individual.svg") ;
  virtual-business: url("~@/assets/images/order_cards/frozen/virtual-business.svg") ;
  virtual-individual: url("~@/assets/images/order_cards/frozen/virtual-individual.svg") ;
}
.card {
  background-size: contain;
  background-repeat: no-repeat;

  position: relative;

  &-content {
    position: relative;
    border-radius: 14px;
    overflow: hidden;

    svg {
      width: 100%;
    }

    &-marker {
      border-radius: 15px;
      background-color: #19191C;

      position: absolute;
      left: 10px;
      bottom: 20px;
      padding: 8px 12px;

      color: #FFFFFF;
      font-family: "Graphik", serif;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 14px;
    }
  }

  &-menu {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #19191C;
    border: 1px solid #2D2D30;
    border-radius: 14px;

    opacity: 0;
    transition: opacity .3s ease;

    box-sizing: border-box;

    box-shadow: 0 4px 9px 0 rgba(0,0,0,0.24);

    & > div {
      height: 100%;
      width: 100%;
      display: flex;
    }
  }

  &:hover {
    .card-menu {
      display: block;
      opacity: 1;
    }
  }

  &.frozen,
  &.closed {
    .card-content-marker {
      padding-right: 38px;

      &:after {
        position: absolute;
        right: 6px;
        top: 4px;
        content: ' ';

        display: block;
        height: 24px;
        width: 24px;

        background-repeat: no-repeat;
      }
    }
  }

  &.frozen {
    position: relative;

    .card-content-marker {
      &:after {
        background-image: url("~@/assets/images/order_cards/freeze.svg");
      }
    }

    .card-content::before {
      display: block;
      width: 100%;
      height: 100%;
      content: ' ';
      position: absolute;
      background-size: cover;
      background-repeat: no-repeat;
    }

    each(@set, {
      &.@{key} .card-content::before {
        background-image: @value
      }
    });
  }

  &.closed {
    .card-content-marker {
      &:after {
        background-image: url("~@/assets/images/order_cards/close.svg");
      }
    }
  }
}

.card-menu {
  .close {
    position: absolute;
    top: 20px;
    right: 15px;
    display: flex;
    align-items: center;

    span {
      opacity: 0;
      margin-right: 6px;
      font-size: 12px;
      transition: opacity .3s ease;
      color: #EA4C17;
      text-transform: uppercase;

      @media all and (max-width: 1000px) {
        opacity: 1;
      }
    }

    &:hover {
      span {
        opacity: 1;
      }
    }
  }
}
</style>
