<template>
  <div class="cmn" :class="classList" v-click-outside="close">
    <div class="cmn-marker">
      <transition name="fade">
        <div class="cmn-marker-close" v-if="opened" @click.prevent="close" >
          <icon name="icp_close" />
        </div>
        <div class="cmn-marker-info" v-else @click.prevent="open">
          <icon name="ic_i" />
        </div>
      </transition>
    </div>

    <div class="cmn-title">{{ title }}</div>
    <div class="cmn-description">{{ description }}</div>
  </div>
</template>

<script>
import Icon from "../../../../libs/Icon";
import ClickOutside from 'vue-click-outside'

export default {
  name: 'CardMenuNotification',
  components: {
    Icon
  },
  directives: {
    Icon,
    ClickOutside
  },
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      opened: false
    }
  },
  computed: {
    classList () {
      return {
        opened: this.opened,

        // By card notification type
        warning: this.type === 'WARNING',
        error: this.type === 'ERROR',
        info: this.type === 'INFO',
      }
    },

    type () {
      return this.notification.type
    },
    title () {
      return this.notification.title
    },
    description () {
      return this.notification.message
    }
  },
  methods: {
    open () {
      this.opened = true
    },
    close () {
      this.opened = false
    }
  }
}
</script>

<style lang="less" scoped>
// Card Menu Notification
.cmn {
  &-description,
  &-title {
    display: none;
    opacity: 0;
    transition: opacity .3s ease 1s;
  }

  &-marker {
    position: absolute;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 18px;
    height: 18px;

    top: -9px;
    right: -9px;

    cursor: pointer;

    border-radius: 50%;
    border: 2px solid #19191C;
    box-sizing: content-box;

    transition:
        top .3s ease .1s,
        bottom .3s ease .1s,
        left .3s ease .1s,
        right .3s ease .1s,
        background-color .3s ease,
        transform .3s ease;

    svg {
      display: block;
      pointer-events: none;
    }

    &:hover {
      transform: scale(1.1);
    }
  }

  &.warning {
    .cmn-marker { background-color: #FDE46A; }
    .cmn-title { color: #FDE46A; }
  }
  &.error {
    .cmn-marker { background-color: #EA4C17; }
    .cmn-title { color: #EA4C17; }
  }
  &.info {
    .cmn-marker { background-color: #5AC8F5; }
    .cmn-title { color: #5AC8F5; }
  }

  box-sizing: content-box;

  left: 100%;
  top: 0;

  display: block;
  position: absolute;
}

.cmn-description {
  color: #FFFFFF;

  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
}

.cmn-title {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
}

.cmn.opened {
  left: 0;
  top: 0;

  height: 100%;
  width: 100%;

  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;

  box-sizing: border-box;
  box-shadow: 0 4px 9px 0 rgb(0 0 0 / 24%);
  z-index: 1;

  background-color: #19191C;
  border: 1px solid #2D2D30;
  border-radius: 14px;

  .cmn-description { display: block; opacity: 0.8 }
  .cmn-title       { display: block; opacity: 1 }

  .cmn-marker {
    top: 20px;
    right: 20px;

    width: 21px;
    height: 21px;

    border: none;
    background-color: #19191C;

    &:hover {
      background-color: rgba(255, 255, 255, .12);
    }
  }
}

.card:hover {
  .cmn-marker {
    top: 20px;
    right: 20px;
  }
}
</style>