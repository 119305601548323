<template>
  <div class="block-limits-wrapper">
    <div class="block-limits" v-for="item in tabItems" :key="item.id" >
      <div class="block-limits-row">
        <div class="block-limits-title">
          <span>{{ item.label }}</span>
          <icon name="ic_info_2" v-tooltip="{content: item.tooltip}" v-if="item.tooltip"/>
        </div>
        <div class="block-limits-switcher text-right" v-if="limits[item.id].switch.enabled">
          <button-switcher v-model="limits[item.id].switch.value" @input="changeSwitcher(item.id, $event)" class="switcher" size="lg" :disabled="loading"/>
        </div>
      </div>
      <template v-if="limits[item.id].switch.value !== false">
        <div class="block-limits-data" :class="{yellow: isMaxLimits(item.id), loading: loading}">

          <div class="progress">
            <div class="progress-title">
              Transaction limit <span class="progress-value">{{ limitsById(item.id).usage.transaction | numeral('0,0.00') }} {{ currencyById(item.id) }}</span>
            </div>
            <div class="progress-bar">
              <div class="progress-bar-limit" :style="progressWidthStyles(100, 100)" />
            </div>
          </div>

          <div class="progress">
            <div class="progress-title">
              Daily limit of <span class="progress-value">{{ limitsById(item.id).usage['daily'].total | numeral('0,0.00') }} {{ currencyById(item.id) }}</span>
            </div>
            <div class="progress-bar">
              <div class="progress-bar-limit" :style="progressWidthStyles(limitsById(item.id).usage['daily'].total, limitsById(item.id).usage['daily'].used)" />
            </div>
            <div class="progress-subtitle">
              <div>
                Will be updated on <span class="progress-value">{{ dateFormat(limitsById(item.id).usage['daily'].reset) }}</span>
              </div>
              <div>
                <span class="progress-value">{{ limitsById(item.id).usage['daily'].available | numeral('0,0.00') }} {{ currencyById(item.id) }}</span> remaining
              </div>
            </div>
          </div>

          <div class="progress">
            <div class="progress-title">
              Monthly limit of <span class="progress-value">{{ limitsById(item.id).usage['monthly'].total | numeral('0,0.00') }} {{ currencyById(item.id) }}</span>
            </div>
            <div class="progress-bar">
              <div class="progress-bar-limit" :style="progressWidthStyles(limitsById(item.id).usage['monthly'].total, limitsById(item.id).usage['monthly'].used)" />
            </div>
            <div class="progress-subtitle">
              <div>
                Will be updated on <span class="progress-value">{{ dateFormat(limitsById(item.id).usage['monthly'].reset) }}</span>
              </div>
              <div>
                <span class="progress-value">{{ limitsById(item.id).usage['monthly'].available | numeral('0,0.00') }} {{ currencyById(item.id) }}</span> remaining
              </div>
            </div>
          </div>

          <div class="progress text-right" v-if="card.permissions.canSetLimits">
            <button class="default-button" :class="{'default-button-white': !isMaxLimits(item.id)}" @click="toggle(item.id)" :disabled="loading">Change to {{ isMaxLimits(item.id) ? 'default' : 'maximum' }} amount</button>
          </div>

        </div>
      </template>
      <template v-else>
        <div class="block-limits-empty" >You switch off the ability to make {{ item.desc }}</div>
      </template>
    </div>
  </div>
</template>

<script>
import ButtonSwitcher from "../../../../../libs/ButtonSwitcher";
import Icon from "../../../../../libs/Icon";
import {isEqualInt} from "../../../../../libs/functions";

export default {
  name: 'ChangeLimits',
  components: {
    ButtonSwitcher,
    Icon
  },
  props: {
    card: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      limits: {},
      groups: [
        {
          id: 'internet_purchase',
          label: 'Online payments',
          desc: 'online payment'
        },
        {
          id: 'purchase',
          label: 'POS',
          desc: 'POS operation',
          tooltip: "POS terminal is a credit card terminal at a retail store. <br/>" +
              "POS terminal accepts:<br/>" +
              "- Chip Cards;<br/>" +
              "- Magstripe Cards;<br/>" +
              "- Contactless Payments."
        },
        {
          id: 'contactless_purchase',
          label: 'Contactless purchase',
          desc: 'contactless purchase',
          tooltip: "Contactless payment is a transaction that can be made<br/>" +
              "without physically attaching the buyer's smartphone <br/>" +
              "or credit card to the retailer's POS system using:<br/>" +
              "- NFC technology;<br/>" +
              "- Mobile Wallet."
        },
        {
          id: 'withdrawal',
          label: 'ATM withdrawal',
          desc: 'ATM withdrawal'
        }
      ]
    }
  },
  async created() {
    await this.loadCardLimits()
  },
  sockets: {
    async 'updateCardLimits' ({ card_id }) {
      if (isEqualInt(card_id, this.card.id)) {
        await this.loadCardLimits()
        this.$emit('update-card')
        this.loading = false
      }
    }
  },
  computed: {
    tabItems () {
      return this.groups.filter(i => this.limitsById(i.id))
    },
  },
  methods: {
    toggle (id) {
      this.loading = true
      this.$sdk.card.limits.update(this.card.id, {
        [id]: {
          value: (this.isMaxLimits(id) ? 'DEFAULT' : 'MAXIMUM')
        }
      }, 0)
    },

    changeSwitcher (id, val) {
      this.loading = true
      this.$sdk.card.limits.update(this.card.id, {
        [id]: {
          switch: {
            value: val
          }
        }
      }, 0)
    },

    limitsById(id) {
      return this.limits[id]
    },

    currencyById(id) {
      return this.limitsById(id).usage.currency_id
    },
    
    isMaxLimits (id) {
      return this.limitsById(id).value === 'MAXIMUM'
    },

    progressWidthStyles (total, used) {
      return {
        width: (100 / total) * used + '%'
      }
    },

    dateFormat (unixtime) {
      return this.$moment.unix(unixtime).format('D MMMM')
    },

    /**
     * Load info about
     * @returns {Promise<void>}
     */
    async loadCardLimits () {
      let { data } = await this.$sdk.card.limits.get(this.card.id)
      this.limits = data
    }
  }
}
</script>

<style lang="less">
.limits-tabs {
  margin: 40px 0;
}

.block-limits {
  display: block;

  &-data {
    opacity: 1;
    transition: opacity .3s ease;
  }

  & + .block-limits {
    padding-top: 30px;
    border-top: 1px solid rgba(255, 255, 255, .1);
    margin-top: 30px;
  }

  &-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }

  &-title {
    color: #FFFFFF;
    display: block;

    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;

    span + svg {
      fill: #FFF;
      margin-left: 10px;

      position: relative;
      top: -1px;

      cursor: help;
    }

    span,
    svg {
      vertical-align: middle;
    }
  }

  &-empty {
    color: rgba(255, 255, 255, .5);
    margin-top: 10px;
  }

  .buttons {
    margin-top: 30px;
    text-align: right;
  }
}

.progress {
  margin-top: 30px;

  &-title {
  }

  &-bar {
    border-radius: 5px;
    height: 10px;
    width: 100%;

    display: block;
    margin: 10px 0;
    position: relative;
    overflow: hidden;
    background-color: #464646;

    &-limit {
      background-color: #fff;
      position: absolute;
      height: 100%;
      //min-width: 500px;
      border-radius: 5px;
      transition: background-color .5s ease;
    }
  }

  &-value {
    transition: color .5s ease;
  }

  &-subtitle {
    display: flex;
    gap: 10px;

    div:first-child {
      margin-right: auto;
    }
  }

  &-title,
  &-subtitle {
    color: rgba(255, 255, 255, .6);

    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;

    transition: color .5s ease;

    .progress-value {
      color: #fff;
      display: inline;
    }
  }
}

.block-limits-data.yellow {
  .progress {
    &-subtitle,
    &-title {
      color: #FFF;
    }
    &-value {
      color: #FDE46A;
    }
    &-bar-limit {
      background-color: #FDE46A;
    }
  }
}

.block-limits-data.loading {
  opacity: .5;
}

</style>