<template>
  <button :class="['switcher', 'switcher-' + size, isActive && 'active']" @click.stop="toggle" />
</template>

<script>
export default {
  name: 'ButtonSwitcher',
  props: {
    value: {
      type: [Array, String, Number, Boolean]
    },
    size: {
      type: String,
      default: 'sm',
      validator: (val) => {
        return ['lg', 'sm'].includes(val)
      }
    },
    values: {
      type: Array,
      default: () => {
        return [true, false]
      },
    }
  },
  computed: {
    val: {
      set (val) {
        return this.$emit('input', val)
      },
      get () {
        return this.value
      }
    },
    isActive () {
      return this.val === this.getTrueValue
    },
    getTrueValue () {
      return this.values[0]
    },
    getFalseValue () {
      return this.values[1]
    }
  },
  methods: {
    toggle () {
      if (this.disabled) return

      this.val = (this.value === this.getTrueValue)
          ? this.getFalseValue
          : this.getTrueValue
    }
  }
}
</script>

<style lang="less">
.switcher {
  background: #424242;

  box-sizing: border-box;
  transition: all .3s ease;
  z-index: 4;
  position: relative;

  cursor: pointer;
  opacity: 1;

  &:before {
    content: '';
    display: block;

    border-radius: 50%;
    box-sizing: border-box;
    transition: all .3s ease;

    position: absolute;
    background-color: #FFFFFF;

    top: 2px;
    left: 2px;
  }

  &:not(:disabled):hover {
    transform: scale(1.1);

    &:before {
      background-color: #FDE46A;
    }
  }

  &.active {
    &:before {
      left: 21px;
      background-color: #FDE46A;
    }
  }

  &-sm {
    border-radius: 13px;

    width: 38px;
    height: 18px;
    padding: 2px;

    &:before {
      width: 14px;
      height: 14px;
    }
  }

  &-lg {
    border-radius: 36px;

    height: 26px;
    width: 51px;

    &:before {
      height: 22px;
      width: 22px;
    }

    &.active {
      background-color: #FDE46A;

      &:before {
        background-color: #FFF;
        left: 27px;
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.01), 0 3px 1px 0 rgba(0, 0, 0, 0.03);
      }
    }

    &:not(:disabled):hover {
      transform: scale(1);

      &:before {
        background-color: #fff;
      }
    }
  }

  &:disabled {
    opacity: .5;
  }
}
</style>